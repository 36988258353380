<template>
  <div @click="onCell" :class="{ dark: type === 'Dark' }" class="link-container">
    <div class="link-content">
      <p class="left">{{ info.content }}</p>
      <i class="right" />
    </div>
    <div class="link-footer">
      <i class="link-icon"/>
      <span class="link-text">{{ info.link }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      value: () => ({})
    },
    type: String
  },
  methods: {
    onCell() {
      const { info } = this

      if (!info.link) return

      this.$emit('onCell', info.link)
    }
  }
}
</script>

<style lang="scss" scoped>
  .link-container {
    width: 100%;
    padding: .56rem .6rem 0;
    background: #FFFFFF;
    box-shadow: 0 0.06rem .36rem 0 rgba(0,0,0,0.08);
    box-sizing: border-box;
    &.dark {
      background: #222222;
      .link-content {
        border-color: #282828;
        .left {
          color: #CFCFCF;
        }
        .right {
          background: url('~@/assets/images/link/actives_icon_more_black@3x.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .link-footer {
        color: #8B8B8B;
        .link-icon {
          background: url('~@/assets/images/link/actives_icon_link_black@3x.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .link-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: .6rem;
      border-bottom: 1px solid #F2F2F2;
      .left {
        width: 10.36rem;
        padding-right: 1.76rem;
        font-size: .60rem;
        font-weight: bold;
        color: #333333;
        line-height: .88rem;
        text-align: justify;
        word-break: break-all;
      }
      .right {
        display: block;
        width: .96rem;
        height: .96rem;
        background: url('~@/assets/images/link/actives_icon_more@3x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .link-footer {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: .72rem;
      padding: .6rem 0;
      font-weight: 400;
      color: #999999;
      .link-icon {
        display: block;
        width: .64rem;
        height: .64rem;
        background: url('~@/assets/images/link/actives_icon_link@3x.png') no-repeat;
        background-size: 100% 100%;
        margin-right: .32rem;
      }
      .link-text {
        width: calc(100% - .88rem - .32rem);
        font-size: .48rem;
        line-height: .68rem;
        // word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
