<template>
  <div :class="[{ dark: type === 'Dark' }, info.columnVal]" class="discuss-contrainer">
    <div style="padding-right: .92rem;box-sizing: border-box;">
      <div class="discuss-frequency">
        <span>{{ info.vol }}</span><span v-if="info.ifTake" style="margin-left: .32rem">已参加</span>
      </div>
      <p class="discuss-title">{{ info.discussTitle }}</p>
      <div class="discuss-stat"><span class="stat-value">{{ info.followNumber || 0 }}</span><span>人参与</span><template v-if="info.columnVal !== 'vote'"><span class="stat-value"> · {{ info.commentNum || 0 }}</span><span>个评论</span></template></div>
    </div>
    <div v-if="info.columnVal === 'topic'" class="discuss-topic">
      <div class="topic-cell">
        <div class="topic-avatar">
          <img v-if="userInfo && userInfo.avatar" :src="userInfo.avatar" />
        </div>
        <p class="topic-text">{{ userInfo && userInfo.nickname ? `${userInfo.nickname}有话要说...` : '写下你想表达的观点吧...' }}</p>
      </div>
    </div>
    <div v-if="info.columnVal === 'vote' && this.info.choiceList && this.info.choiceList.length" class="discuss-vote">
      <div
        v-for="(item) in this.info.choiceList"
        :key="item.optionsUid"
        :class="{ 'vote-cell__is': isStand || info.ifEnd, 'vote-cell__active': item.choiceFlag }"
        class="vote-cell">
        <div class="vote-text">
          <span>{{ item.choiceVal || `选项${item.choiceKey}` }}{{ item.choiceFlag ? '（已选）' : '' }}</span>
          <span v-if="isStand || info.ifEnd">{{ toPercentage(item.choiceNum, 1) }}</span>
        </div>
        <i :style="`width: ${isStand || info.ifEnd ? voteWidth(item.choiceNum) : 0};`" class="vote-line" />
      </div>
    </div>
    <div v-if="info.columnVal === 'ranks'" class="discuss-ranks">
      <div class="ranks-pk">
        <i :class="{ unactive: getStand('BACK').choiceFlag, [standLineClass('JUST')]: isStand || info.ifEnd }" class="pk-line just" />
        <i class="pk-icon" />
        <i :class="{ unactive: getStand('JUST').choiceFlag, [standLineClass('BACK')]: isStand || info.ifEnd }" class="pk-line back" />
      </div>
      <div class="ranks-footer">
        <template v-if="isStand || info.ifEnd">
          <div :class="{ unactive: getStand('BACK').choiceFlag }" class="ranks-text just">
            <span class="percentage">{{ toPercentage(getStand('JUST').choiceNum) }}</span>
            <span class="label">{{ getStand('JUST').choiceVal }}</span>
          </div>
          <div :class="{ unactive: getStand('JUST').choiceFlag }" class="ranks-text back">
            <span class="label">{{ getStand('BACK').choiceVal }}</span>
            <span class="percentage">{{ toPercentage(getStand('BACK').choiceNum) }}</span>
          </div>
        </template>
        <template v-else>
          <span class="ranks-buttom just">{{ getStand('JUST').choiceVal }}</span>
          <span class="ranks-buttom back">{{ getStand('BACK').choiceVal }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    },
    header: {},
    userInfo: null
  },
  computed: {
    // 获取站队正反方
    getStand() {
      return function(key) {
        const arr = this.info.choiceList
        let cur

        for (let i = 0; i < arr.length; i++) {
          if (arr[i].choiceKey === key) {
            cur = arr[i]
            break
          }
        }

        return cur
      }
    },
    // 判断是否已经站队/投票
    isStand() {
      const arr = this.info.choiceList
      let flag = false

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].choiceFlag) {
          flag = true
          break
        }

        continue
      }

      return flag
    },
    // 获取正反方宽度样式
    standLineClass() {
      return function (key) {
        const arr = this.info.choiceList
        let just, back

        for (let i = 0; i < arr.length; i++) {
          if (arr[i].choiceKey === 'JUST') {
            just = arr[i]
          } else if (arr[i].choiceKey === 'BACK') {
            back = arr[i]
          }
        }

        if (just.choiceNum === back.choiceNum) {
          return ''
        } else if (just.choiceNum > back.choiceNum) {
          if (key === 'JUST') {
            return 'beat'
          } else {
            return 'lose'
          }
        } else {
          if (key === 'JUST') {
            return 'lose'
          } else {
            return 'beat'
          }
        }
      }
    },
    // 投票背景宽度计算
    voteWidth() {
      return function (num, fixed) {
        let percentage = this.toPercentage(num, fixed)

        console.info('calc(' + percentage + ' + ' + 2 * parseInt(percentage) / 100 + 'px)', 145)

        return 'calc(' + percentage + ' + ' + 2 * parseInt(percentage) / 100 + 'px)'
      }
    },
    // 投票/站队百分比转化
    toPercentage() {
      return function (num, fixed = 0) {
        const arr = this.info.choiceList
        let sum = 0

        if (!num || !arr || !arr.length) {
          return '0%'
        }

        for (let i = 0; i < arr.length; i++) {
          sum = sum + Number(arr[i].choiceNum || 0)
        }

        let p1 = num / sum
        let p2 = p1 * 100

        return p2.toFixed(fixed) + '%'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .discuss-contrainer {
    position: relative;
    width: 100%;
    padding: .52rem .6rem 0;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0 0.06rem .36rem 0 rgba(0,0,0,0.08);
    overflow: hidden;
    &.dark {
      background: #252525;
      .discuss-title {
        color: #CFCFCF;
      }
      .discuss-stat .stat-value {
        color: #CFCFCF;
      }
      .discuss-topic {
        .topic-cell {
          background: #1D1D1D;
          &::after {
            background: url('../../assets/images/discuss_new/huati_icon_comment_yinhao@2x.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .discuss-vote {
        .vote-cell {
          border-color: #5E5E5E !important;
          .vote-text {
            color: #CFCFCF;
          }
          .vote-line {
            background: #333333;
          }
          &__is {
            border-color: #333333 !important;
          }
          &__active {
            border-color: #553B00 !important;
            .vote-line {
              background: #553B00;
            }
          }
        }
      }
      .discuss-ranks {
        .ranks-pk {
          .pk-icon {
            background: url('../../assets/images/discuss_new/huati_icon_pk_black@2x.png') no-repeat;
            background-size: 100% 100%;
          }
          .pk-line {
            &.just { // 正方
              background: #CFCFCF;
              &.unactive {
                background: #727272;
              }
            }
            &.back {
              background: #FFA600;
              &.unactive {
                background: #A26900;
              }
            }
          }
        }
        .ranks-footer {
          .ranks-buttom {
            &.just {
              background: #CFCFCF;
              color: #131313;
            }
            &.back {
              background: #FFA600;
              color: #131313;
            }
          }
          .ranks-text {
            &.just {
              color: #CFCFCF;
              &.unactive {
                color: #727272;
              }
            }
            &.back {
              color: #FFA600;
              &.unactive {
                color: #A26900;
              }
            }
          }
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      top: 0;
      right: .6rem;
    }
    &.topic {
      &::after {
        background: url('../../assets/images/discuss_new/huati_tab_topic@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    &.ranks {
      &::after {
        background: url('../../assets/images/discuss_new/huati_tab_pk@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    &.vote {
      &::after {
        background: url('../../assets/images/discuss_new/huati_tab_vote@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .discuss-frequency {
      font-size: .48rem;
      font-weight: 400;
      color: #999999;
      line-height: .68rem;
      margin-bottom: .56rem;
    }
    .discuss-title {
      font-size: .6rem;
      font-weight: bold;
      color: #333333;
      line-height: .84rem;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      /*! autoprefixer: on */
    }
    .discuss-stat {
      margin-top: .4rem;
      font-size: .48rem;
      font-weight: 400;
      color: #999999;
      line-height: .68rem;
      .stat-value {
        color: #333333;
      }
    }
    .discuss-topic {
      padding: .8rem 0 1.04rem;
      .topic-cell {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 1.6rem;
        padding: 0 .28rem;
        background: #F0F0F0;
        box-sizing: border-box;
        &::after {
          content: '';
          position: absolute;
          width: .48rem;
          height: .48rem;
          background: url('../../assets/images/discuss_new/huati_icon_comment_yinhao@2x.png') no-repeat;
          background-size: 100% 100%;
          right: .28rem;
          top: -.2rem;
          z-index: 1;
        }
        .topic-avatar {
          width: 1.04rem;
          height: 1.04rem;
          background: url('../../assets/images/topic4/comment_head_default@2x.png') no-repeat;
          background-size: 100% 100%;
          margin-right: .52rem;
          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .topic-text {
          width: calc(100% - 1.04rem - .52rem);
          font-size: .56rem;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .discuss-vote {
      padding: .8rem 0 1.2rem;
      .vote-cell {
        position: relative;
        width: 100%;
        border: 1px solid #BBBBBB;
        .vote-text {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: .36rem .56rem;
          box-sizing: border-box;
          font-size: .56rem;
          font-weight: 400;
          color: #333333;
          line-height: .8rem;
          z-index: 2;
        }
        .vote-line {
          position: absolute;
          width: 0;
          height: 100%;
          background: #F2F2F2;
          transition: width 1s ease-out;
          left: 0;
          top: 0;
          z-index: 1;
        }
        &__is {
          border: 1px solid #F2F2F2;
          .vote-text {
            justify-content: space-between;
          }
        }
        &__active {
          border: 1px solid #FEF1D3 !important;
          .vote-line {
            background: #FEF1D3;
          }
        }
        &:not(:last-child) {
          margin-bottom: .4rem;
        }
      }
    }
    .discuss-ranks {
      padding: .88rem 0 1.2rem;
      .ranks-pk {
        display: flex;
        align-items: center;
        .pk-icon {
          display: block;
          width: .84rem;
          height: .48rem;
          margin: 0 .32rem;
          background: url('../../assets/images/discuss_new/huati_icon_pk.png') no-repeat;
          background-size: 100% 100%;
        }
        .pk-line {
          display: block;
          width: calc((100% - 1.48rem) / 2);
          height: .32rem;
          transition: width 1s ease-out;
          &.beat { // 多人
            width: calc(((100% - 1.48rem) / 4) * 3);
          }
          &.lose { // 少人
            width: calc(((100% - 1.48rem) / 4) * 1);
          }
          &.just { // 正方
            background: #131313;
            &.unactive {
              background: #BBBBBB;
            }
          }
          &.back {
            background: #FFA600;
            &.unactive {
              background: #F6C56B;
            }
          }
        }
      }
      .ranks-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: .4rem;
        &.active {
          padding-top: .68rem;
        }
        .ranks-buttom {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3.6rem;
          height: 1.32rem;
          font-size: .56rem;
          font-weight: bold;
          &.just {
            background: #131313;
            color: #FFA600;
          }
          &.back {
            background: #FFA600;
            color: #131313;
          }
        }
        .ranks-text {
          display: flex;
          align-items: flex-end;
          &.just {
            color: #131313;
            &.unactive {
              color: #BBBBBB;
            }
            .label {
              margin-left: .24rem;
            }
          }
          &.back {
            color: #FFA600;
            &.unactive {
              color: #F6C56B;
            }
            .label {
              margin-right: .24rem;
            }
          }
          .label {
            font-size: .56rem;
            font-weight: bold;
            line-height: 1.12rem;
          }
          .percentage {
            font-size: .96rem;
            font-weight: bold;
            line-height: 1.32rem;
          }
        }
      }
    }
  }
</style>
