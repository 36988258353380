<template>
  <div :class="{ dark: type === 'Dark', rank: isRank }" class="cell-container">
    <span v-if="isRank" :class="{ 'no-1': isRank === 1 }" class="rank-icon">NO.{{ isRank }}</span>
    <div class="left">
      <p class="title">{{ info.title }}</p>
      <div class="tip-box">
        <span v-if="isAuthor" class="tip-items">{{ info.authorName.replace(/ /gi, '/') }}</span>
        <span v-else :class="{ is__new: info.ifNew }" class="tip-items">{{ channel }}</span>
        <span v-if="info.readNum && info.readNum > 999 && isNum" class="tip-items">{{ info.readNum | dealNum }}人已阅</span>
        <!-- time 长度模糊判断 是否是纯时间显示 -->
        <span v-if="time" :class="{ is__time: time.length > 7 }" class="tip-items">
          {{ time }}
        </span>
      </div>
    </div>
    <div :class="{ is__video: info.resourceType === 40 }" class="right">
      <img :src="info.coverImage" style="object-fit: cover;" width="100%" height="100%" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    },
    isTime: {
      type: Boolean,
      default: false
    },
    isNum: {
      type: Boolean,
      default: true
    },
    isRank: {
      type: Number,
      default: 0
    },
    isAuthor: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    dealNum(num) {
      if (num <= 9999) {
        return num
      } else {
        return (num / 10000).toFixed(1) + 'w'
      }
    }
  },
  computed: {
    channel() {
      const { info } = this

      if (info.ifNew) {
        return 'NEW'
      } else if (info.resourceType === 40) {
        return '视频'
      } else if (info.channelName) {
        return info.channelName
      }

      return '文章'
    },
    time() {
      const { info, isTime } = this

      if (!info.publishTime) {
        return false
      }

      let timestamp = (new Date().getTime() - info.publishTime) / 1000;
      if (timestamp < 60) {
        //小于1分钟
        return "刚刚";
      } else if (timestamp > 60 && timestamp < 60 * 60) {
        //小于1小时
        return parseInt(timestamp / 60) + "分钟前";
      } else if (timestamp > 60 * 60 && timestamp < 60 * 60 * 24) {
        //小于1天
        return parseInt(timestamp / 60 / 60) + "小时前";
      } else {
        if (isTime) {
          return this.utils.formatDate(info.publishTime, 'yyyy.MM.dd')
        }
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .cell-container {
    display: flex;
    width: 100%;
    height: 4.84rem;
    padding: .6rem;
    background: #FFFFFF;
    box-shadow: 0 0.06rem .36rem 0 rgba(0,0,0,0.08);
    box-sizing: border-box;
    &.rank {
      position: relative;
      height: 5.56rem;
      padding-top: 1.32rem;
      .rank-icon {
        position: absolute;
        font-family: "DIN-Alternate-Bold";
        font-size: .72rem;
        font-weight: bold;
        line-height: .84rem;
        color: #FFA600;
        left: .6rem;
        top: .32rem;
        z-index: 1;
        &.no-1 {
          color: #CF450A;
        }
      }
    }
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: .97rem;
      .title {
        font-size: .6rem;
        font-weight: bold;
        color: #333333;
        line-height: .84rem;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /*! autoprefixer: on */
      }
      .tip-box {
        display: flex;
        align-items: center;
        .tip-items {
          position: relative;
          font-size: .44rem;
          font-weight: 400;
          color: #999999;
          line-height: .64rem;
          &.is__time {
            font-family: "Nr-regular";
            font-size: .72rem;
            line-height: normal;
          }
          &.is__new {
            color: #FFA600;
            font-weight: bold;
          }
          &:not(:last-child) {
            padding-right: .44rem;
            margin-right: .4rem;
            &::after {
              content: "";
              position: absolute;
              width: .04rem;
              height: .32rem;
              background: #BBBBBB;
              top: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 1;
            }
          }
        }
      }
    }
    .right {
      position: relative;
      width: 3.48rem;
      height: 3.48rem;
      &.is__video::after {
        content: "";
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        background: url('~@/assets/images/content-cell/home_icon_bofang.png') no-repeat;
        background-size: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
      }
    }
    &.dark {
      background: #252525;
      .left {
        .title {
          color: #CFCFCF;
        }
        .tip-items {
          color: #666666;
          &:not(:last-child)::after {
            background: #666666;
          }
        }
      }
    }
  }
</style>
