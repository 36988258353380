<template>
  <div>
    <div v-if="!err" class="activity-container">
      <template v-if="info">
        <div class="activity-header">
          <p id="activityTitle" class="title">{{ info.activityName }}</p>
          <p v-if="!info.ifAlways" class="tip">活动期：{{ info.beginTime | golobalFormatDate }} ~ {{ info.endTime | golobalFormatDate }}</p>
        </div>
        <div v-if="info.activityRelationList && info.activityRelationList.length" class="activity-content">
          <template v-for="(item, index) in info.activityRelationList">
            <div v-if="item.contentType === 1" v-html="htmlContent(item.content)" :key="index" class="content-html" />
            <linkComponent v-else-if="item.contentType === 2" :key="index" :info="item" class="content-card" />
            <template v-else-if="item.contentType === 3">
              <content-cell
                v-if="item.resourceType === 10"
                :key="index"
                :info="item.resourceObject"
                :isTime="true"
                class="content-card" />
              <discuss-card
                v-if="[23, 24, 25].includes(item.resourceType)"
                :key="index"
                :info="item.resourceObject"
                class="content-card" />
            </template>
          </template>
        </div>
      </template>
    </div>

    <err v-else :errStatus="err"></err>
  </div>
</template>

<script>
import contentCell from "@/components/content-cell"
import discussCard from '@/components/discuss-card'
import linkComponent from './components/linkComponent.vue'
import err from '@/components/err'

export default {
  components: {
    err,
    contentCell,
    linkComponent,
    discussCard,
  },
  data() {
    return {
      // 错误页面类型
      err: "",
      // 主体
      info: null,
    }
  },
  computed: {
    htmlContent() {
      return (str) => {
        if (!str) return ''

        return this.dealHtml(str)
      }
    }
  },
  methods: {
    // 正文数据处理
    dealHtml(str) {
      const tLineReg = this.getTagByClassUsingRegex("span", "yellow-line"); // 删除以前的小标题附带子标签
      const tReg = /<h5[^>]*class="articleTile[^\"]*"[^>]*>([^<]*?)<\/h5>/gi; // 修改小标题布局以及样式
      const imgReg = /<p[^>]*>\s*<img([^>|]+)>\s*(<span[^>]*>([^<\/]*)<\/span>)?\s*<\/p>/gi;

      return this.initHtml(str)
      .replace(tLineReg, "")
      .replace(tReg, function (re, $s) {
        return `<p class="time-article-subtitle">${$s}</p>`
      })
      .replace(imgReg, function (re, $s, $z, $c) {
        return `<div id="imgTextBox_0" class="time-article-imgText-box"><img ${$s} data-alt=${$c || ''} />${$z ? '<span class="time-article-imgText-text">'+ $z +'</span>' : ''}</div>`
      })
    },
    // 初始化富文本
    initHtml(str) {
      let s = str;

      s = s.replace(/(\n)/gi, "")
      s = s.replace(/(\t)/gi, "")
      s = s.replace(/(\r)/gi, "")
      s = s.replace(/style=\"[^\"]*\"/gi, "")
      s = s.replace(/<br\/>/gi, "")
      s = s.replace(/<([b-u|w-z][a-z]*?)(?:\s+?[^>]*?)?>\s*?<\/\1>/gi, "")

      return s;
    },
    // 筛选指定claas标签
    getTagByClassUsingRegex(tag, cls) {
      const reg = new RegExp(
        "<" +
          tag +
          "[^>]*class[\\s]?=[\\s]?['\"]" +
          cls +
          "[^'\"]*['\"][\\s\\S]*?</" +
          tag +
          ">",
        "g"
      );

      return reg;
    },
    // 获取活动详情
    async getActivityInfo() {
      const { token: Authorization, eid } = this.$route.query;

      if (this.$route.query.scode) {
        const res = await this.$api.cj.getResourceDetail({ scode: this.$route.query.scode }, { Authorization, eid });
        if (res.retCode == 200) {
          console.info(res.data, 121)
          let { data } = res

          if (data.activityRelationList && data.activityRelationList.length) {
            data.activityRelationList = data.activityRelationList.map(item => {
              if (item.contentType === 3) {
                let resourceObject

                if (item.resourceType === 10) {
                  resourceObject = {
                    ...item.resourceObject,
                    resourceUid: item.resourceObject.articleUid,
                    pageLink: item.resourceObject.qrLink || "",
                    title: item.resourceObject.articleTitle || "",
                    readNum: item.resourceObject.viewNum || 0,
                    publishTime: item.resourceObject.createTime || "",
                  }
                } else if ([23, 24, 25].includes(item.resourceType)) {
                  resourceObject = {
                    ...item.resourceObject
                  }
                }

                return {
                  ...item,
                  resourceObject
                }
              } else if (item.contentType === 1) {
                return {
                  ...item,
                  content: item.content.replace(/static.tfcaijing.com/g, "zebra.tfcaijing.com")
                }
              }

              return item
            })
          }

          this.info = data
        } else if (res.retCode == 400406) {
          //链接已失效
          this.err = "resourceErr01"
        } else if (res.retCode == 400404) {
          this.err = "resourceErr02"
        } else {
          this.err = "serverErr"
        }
      } else if (sessionStorage.getItem("previewData")) {
        let previewData = JSON.parse(sessionStorage.getItem("previewData"))

        if (previewData.activityRelationList && previewData.activityRelationList.length) {
          previewData.activityRelationList = previewData.activityRelationList.map(item => {
            if (item.contentType === 3) {
              let resourceObject

              if (item.resourceType === 10) {
                resourceObject = {
                  ...item.resourceObject,
                  resourceUid: item.resourceObject.articleUid,
                  pageLink: item.resourceObject.qrLink || "",
                  title: item.resourceObject.articleTitle || "",
                  readNum: item.resourceObject.viewNum || 0,
                  publishTime: item.resourceObject.createTime || "",
                }
              } else if ([23, 24, 25].includes(item.resourceType)) {
                resourceObject = {
                  ...item.resourceObject
                }
              }

              return {
                ...item,
                resourceObject
              }
            } else if (item.contentType === 1) {
              return {
                ...item,
                content: item.content.replace(/static.tfcaijing.com/g, "zebra.tfcaijing.com")
              }
            }

            return item
          })
        }

        this.info = previewData
      }
    },
  },
  created() {
    this.getActivityInfo()
  }
}
</script>

<style lang="scss" scoped>
  .activity-container {
    padding: .64rem 0 1.12rem;
    .activity-header {
      padding: 0 .64rem;
      .title {
        font-size: .96rem;
        font-weight: 600;
        color: #111111;
        line-height: 1.36rem;
        text-align: justify;
        word-break: break-all;
      }
      .tip {
        margin-top: .48rem;
        font-size: .48rem;
        font-weight: 400;
        color: #333333;
        line-height: .68rem;
      }
    }
    .activity-content {
      padding: .88rem .32rem 0;
      .content-card + .content-html, .content-html + .content-card, .content-html + .content-html {
        margin-top: .88rem;
      }
      .content-card + .content-card {
        margin-top: .32rem;
      }
      .content-html {
        padding: 0 .32rem;
        color: #111111;
        font-weight: 400;
        text-align: justify;
        word-break: normal;
        font-size: .68rem;
        line-height: 1.16rem;
        /deep/ img {
          width: 100% !important;
          height: auto !important;
          font-size: 0 !important;
          user-select: none !important;
        }
        /deep/ video {
          width: 100% !important;
          height: auto !important;
        }
        /deep/ .time-article-subtitle {
          position: relative;
          display: inline-block;
          padding-left: 0.8rem;
          font-weight: bold;
          color: #000000;
          font-size: 0.68rem !important;
          line-height: 1.16rem !important;
          &::after {
            content: "";
            position: absolute;
            background: url("~@/assets/images/article_icon_title_sign@2x.png")
              no-repeat;
            background-size: 100% 100%;
            width: 0.32rem;
            height: 0.64rem;
            top: 0.195rem;
            left: 0;
            z-index: 1;
          }
        }
        /deep/ .time-article-imgText-text {
          display: inline-block;
          width: 100%;
          margin-top: 0.44rem;
          font-weight: 400;
          color: #999999;
          font-size: 0.56rem !important;
          line-height: 0.8rem !important;
        }
        /deep/ {
          > *:not(:last-child) {
            margin-bottom: 0.88rem !important;
          }
        }
      }
    }
  }
  .comment-wp {
    width: 100%;
    background: #fff;
    // padding-bottom: 0.68rem;
    .comment-hd {
      display: flex;
      align-items: center;
      width: 100%;
      height: 2.16rem;
      padding: 0 0.68rem;
      font-size: 0.68rem;
      font-weight: bold;
      color: #333333;
      box-sizing: border-box;
    }
    .comment-content {
      width: 100%;
      box-sizing: border-box;
    }
    .nothing-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0 2rem;
      > img {
        width: 7.84rem;
      }
    }
  }
  .fd {
    position: fixed;
    width: 13.64rem;
    height: 1.48rem;
    background: url('~@/assets/images/activty/activity_btn_download@2x.png') no-repeat;
    background-size: 100%;
    left: 0;
    right: 0;
    bottom: .96rem;
    margin: auto;
    z-index: 2;
  }
</style>
